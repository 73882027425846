import request from '@/utils/request'

// banner查询一览 带分页
export function bannerList(data) {
  return request({
    url: '/weChatCarousel/carouselPage',
    method: 'post',
    data
  })
}

// banner详情
export function bannerListById(query) {
  return request({
    url: '/weChatCarousel/getCarouselDetail',
    method: 'get',
    params: query
  })
}

// 新增banner
export function bannerSave(data) {
  return request({
    url: '/weChatCarousel/insert',
    method: 'post',
    data
  })
}

// 修改banner
export function bannerUpdate(data) {
  return request({
    url: '/weChatCarousel/update',
    method: 'post',
    data
  })
}

// 删除banner
export function bannerDeleteById(query) {
  return request({
    url: '/weChatCarousel/carouselDelete',
    method: 'get',
    params:query
  })
}
//
export function carouselActivate(query) {
  return request({
    url: '/weChatCarousel/carouselActivate',
    method: 'get',
    params:query
  })
}

/*  */

// 查询用户一览带分页
export function userList(data) {
  return request({
    url:  '/admin_user/v1/list',
    method: 'post',
    data
  })
}

// 根据用户主键ID取得用户详情
export function userDetail(query) {
  return request({
    url: '/admin_user/v1/detail',
    method: 'get',
    params: query
  })
}

// 添加用户
export function addUser(data) {
  return request({
    url: '/admin_user/v1/add',
    method: 'post',
    data
  })
}

// 修改登陆状态
export function userNotState(query) {
  return request({
    url: '/admin_user/v1/notState',
    method: 'get',
    params: query
  })
}

// 冻结用户
export function userNotFreeze(query) {
  return request({
    url: '/admin_user/v1/notFreeze',
    method: 'get',
    params: query
  })
}

// 删除用户
export function deleteUser(query) {
  return request({
    url: '/admin_user/v1/delete',
    method: 'get',
    params: query
  })
}

// 编辑用户
export function editUser(data) {
  return request({
    url: '/admin_user/v1/edit',
    method: 'post',
    data
  })
}

// 修改密码
export function modifyPassword(data) {
  return request({
    url: '/admin_user/v1/modifyPassword',
    method: 'post',
    data
  })
}

// 重置密码
export function resetPassword(query) {
  return request({
    url: '/admin_user/v1/resetPassword',
    method: 'get',
    params: query
  })
}


/*  以下为角色管理接口  */

// 查询角色一览带分页
export function roleList(data) {
  return request({
    url: '/admin_role/v1/list',
    method: 'post',
    data
  })
}

// 查询角色一览 不带分页
export function roleListAll(data) {
  return request({
    url: '/admin_role/v1/listAll',
    method: 'post',
    data
  })
}

// 根据角色主键ID取得角色详情
export function roleDetail(query) {
  return request({
    url: '/admin_role/v1/detail',
    method: 'get',
    params: query
  })
}

// 添加角色
export function addRole(data) {
  return request({
    url: '/admin_role/v1/add',
    method: 'post',
    data
  })
}

// 删除角色
export function deleteRole(query) {
  return request({
    url: '/admin_role/v1/delete',
    method: 'get',
    params: query
  })
}

// 编辑角色
export function editRole(data) {
  return request({
    url: '/admin_role/v1/edit',
    method: 'post',
    data
  })
}

/*  以下为权限管理接口  */
// 查询权限一览 带分页
export function rightsList(data) {
  return request({
    url: '/admin_auth/v1/list',
    method: 'post',
    data
  })
}

// 查询权限一览 不带分页
export function rightsListAll(query) {
  return request({
    url: '/admin_auth/v1/listAll',
    method: 'get',
    params: query
  })
}

// 根据主键ID查询权限详情
export function rightsDetail(query) {
  return request({
    url: '/admin_auth/v1/detail',
    method: 'get',
    params: query
  })
}

// 新增权限
export function addRights(data) {
  return request({
    url: '/admin_auth/v1/add',
    method: 'post',
    data
  })
}

// 删除权限
export function deleteRights(query) {
  return request({
    url: '/admin_auth/v1/delete',
    method: 'get',
    params: query
  })
}

// 修改权限
export function updateRights(data) {
  return request({
    url: '/admin_auth/v1/update',
    method: 'post',
    data
  })
}
